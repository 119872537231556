<template>
  <UiPopup
    :model-value="modelValue"
    size="extraBig"
    class="py-12"
    hidden
    @update:model-value="emits('update:modelValue', false)"
  >
    <div class="-mt-10">
      <div>
        <img :src="item.attachment[0].publicUrl" class="mx-auto h-[calc(100vh_-_236px)] rounded-2xl object-contain" />
      </div>
      <div class="mt-4 flex items-center justify-between">
        <div class="flex items-center gap-2">
          <UiAvatar :user="user" />
          <div class="text-left">
            <p class="text-caption mb-1">{{ user.name }}</p>
            <p class="text-caption">
              {{ useGetFormattedDate(item.whatsappCreatedAt, true) }},
              {{ format(new Date(item.whatsappCreatedAt), 'HH:mm') }}
            </p>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <button @click="downloadImage(item.attachment[0].publicUrl)">
            <UiIcon name="download" class="text-black-70" />
          </button>
          <button @click="deleteImage">
            <UiIcon name="trash" class="text-black-70" />
          </button>
        </div>
      </div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { useUiStore } from '@/store/ui'
import type { WhatsappMessage, User } from '@/types'
const emits = defineEmits(['update:modelValue', 'delete'])

const uiStore = useUiStore()

type Props = {
  modelValue: boolean
  item: WhatsappMessage
  user: User
}

const props = defineProps<Props>()

const downloadImage = (url: string) => {
  try {
    const link = document.createElement('a')
    link.download = props.item.attachment[0].filename || 'image'
    link.href = url
    link.click()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const deleteImage = () => {
  emits('delete')
}
</script>

<style scoped></style>
